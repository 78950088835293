import { enumToOptions } from "~/utils/FormUtils";
import { CallUIConfigTranscriptSettingsType } from "~/types/CallTypes";

// TODO: not handling the aggregation of multiple models in a single bucket
export const VISIBLE_LLM_LIST: Array<{
  label: string;
  value: string[];
  errorReviewKey?: string;
  disabled?: boolean;
}> = [
  {
    label: "All",
    errorReviewKey: "all",
    value: ["i52", "H2_L3"],
  },
  {
    label: "Polaris 2.0",
    errorReviewKey: "polaris_2",
    value: ["i52"],
  },
  {
    label: "Polaris 1.0",
    errorReviewKey: "polaris_1",
    value: ["H2_L3"],
  },
  {
    label: "Polaris 0.1",
    value: ["prod-H2"], // "prod-H2DPO"],
    disabled: true,
  },
];

export enum ConfigMethodEnum {
  VOIP = "voip",
  PHONE = "phone",
  CHAT = "chat",
}

export enum ConfigTTSServiceEnum {
  AWS_POLLY = "aws_polly",
  DEEPGRAM_AURA = "deepgram_aura",
  CARTESIA = "cartesia",
  GOOGLE = "google",
  EMOTIVOX = "emotivox",
}

export const ConfigMethodOptions = enumToOptions(ConfigMethodEnum, true);

export const getConfigMethodOptions = (methods: ConfigMethodEnum[]) => {
  return ConfigMethodOptions.filter((option) =>
    methods.includes(option.value as ConfigMethodEnum)
  );
};

export const ConfigMethodOptionsAudio = [
  {
    label: "VOIP",
    value: ConfigMethodEnum.VOIP,
  },
  {
    label: "Phone",
    value: ConfigMethodEnum.PHONE,
  },
];

export const ConfigMethodOptionsReduced = [
  {
    label: "Phone",
    value: ConfigMethodEnum.PHONE,
  },
];

export const DEFAULT_TRANSCRIPT_SETTINGS: CallUIConfigTranscriptSettingsType = {
  showSystemPrompts: false,
  showTasks: false,
  showDebug: false,
  showFeedback: false,
  showVotes: false,
  showKickouts: false,
  showSchedules: false,
  showErrorChecker: false,
  showTelemetry: false,
  hideCancelled: false,
  showTimestamps: false,
};

export enum KickoutSensitivityEnum {
  NonClinicalKickoutSensitivity = "non_clinical_kickout_sensitivity",
  PedsLowKickoutSensitivity = "peds_low_kickout_sensitivity",
  LowKickoutSensitivity = "low_kickout_sensitivity",
}
