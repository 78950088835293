import {
  CallUtteranceType,
  MessageType,
  CallUtteranceVoteType,
  CallOutreachType,
  CallOutputType,
  CallOutputKickoutType,
  CallOutputOfflineErrorType,
  CallOutputOfflineErrorsType,
  CallOutputFormFillType,
  CallOutputScheduleType,
} from "~/types/CallTypes";
import { CallFeedbackType } from "~/types/CallFeedbackTypes";
import {
  UICallStatusEnum,
  MESSAGE_TAGS_FLAT,
  SnowMSGSessionTypeEnum,
  CallStatusEnum,
  MESSAGE_TAGS,
  CallOutreachStatusEnum,
  CallDispositionEnum,
  ALL_CALL_DISPOSITIONS,
  CALL_CONNECTED_DISPOSITIONS,
  CALL_COMPLETED_DISPOSITIONS,
  CALL_IDENTITY_VERIFIED_DISPOSITIONS,
  CALL_NOT_COMPLETED_DISPOSITIONS,
  CALL_NOT_CONNECTED_DISPOSITIONS,
  CallOutputTypeEnum,
  CallKickoutColorEnum,
} from "~/constants/CallConstants";
import { ConfigMethodEnum } from "~/constants/CallConfigConstants";
import {
  formatDateTimeShort,
  formatTimeBetweenDates,
  formatISODateToRelative,
} from "~/utils/DateUtils";
import { formatEnumValue } from "~/utils/StringUtils";
import { parseISO } from "~/utils/DateUtils";
import { intersection } from "~/utils/GeneralUtils";

export const getCallStarted = (status?: UICallStatusEnum) => {
  return status && status !== UICallStatusEnum.PENDING;
};

export const getCallEnded = (status?: UICallStatusEnum) => {
  return status && status === UICallStatusEnum.ENDED;
};

export const isCallUtteranceType = (obj: any): obj is CallUtteranceType => {
  return typeof obj === "object" && obj !== null && "utterance_id" in obj;
};

export const isMessageType = (obj: any): obj is MessageType => {
  return typeof obj === "object" && obj !== null && "id" in obj;
};

export const isCallAudio = (callType?: SnowMSGSessionTypeEnum) => {
  return (
    callType === SnowMSGSessionTypeEnum.SESSION_TELEPHONE ||
    callType === SnowMSGSessionTypeEnum.SESSION_WEB_VOICE
  );
};

export const isCallPhone = (callType?: SnowMSGSessionTypeEnum) => {
  return callType === SnowMSGSessionTypeEnum.SESSION_TELEPHONE;
};

/**
 * Get the state of the votes
 * @param votes
 * @returns 1 if good is the most votes, -1 if bad is the most votes, 0 if neutral is the most votes
 */
export const getVotesGood = (votes?: CallUtteranceVoteType[]) => {
  if (!votes) {
    return 0;
  }
  const { good, bad, neutral } = votes.reduce(
    (acc, curr) => {
      const state = isVoteGood(curr);
      if (state === 1) {
        acc.good += 1;
      } else if (state === -1) {
        acc.bad += 1;
      } else {
        acc.neutral += 1;
      }
      return acc;
    },
    { good: 0, bad: 0, neutral: 0 }
  );
  // return 1 if good is the most votes, -1 if bad is the most votes, 0 if neutral is the most votes
  return good > bad && good > neutral
    ? 1
    : bad > good && bad > neutral
    ? -1
    : 0;
};

/**
 * Get the state of the vote
 * @param voteObj
 * @returns 1 if the vote is good, -1 if the vote is bad, 0 if the vote is neutral
 */
export const isVoteGood = (voteObj: CallUtteranceVoteType) => {
  const isGood = Boolean(MESSAGE_TAGS.good[voteObj.vote]);
  if (isGood) {
    return 1;
  } else {
    return -1;
  }
};

export const getVoteLabel = (voteObj: CallUtteranceVoteType) => {
  return MESSAGE_TAGS_FLAT[voteObj.vote];
};

export const getVoteKey = (voteObj: CallUtteranceVoteType) => {
  return `${voteObj.call_id}-${voteObj.utterance_id}-${voteObj.vote}`;
};

export const getCallSubtitle = (
  userName?: string | null,
  startTime?: Date | null,
  endTime?: Date | null
) => {
  let subtitle = userName ?? "Unknown User";
  subtitle += ` on ${formatDateTimeShort(startTime) ?? "Unknown Date"}`;
  subtitle += ` - Duration: ${
    startTime && endTime ? formatTimeBetweenDates(startTime, endTime) : "N/A"
  }`;
  return subtitle;
};

export const getCallTitle = (
  agentName: string,
  patientName: string,
  scriptOrScenarioName?: string
) => {
  let title = `${agentName} ↔ ${patientName}`;
  if (scriptOrScenarioName) {
    title += ` - ${scriptOrScenarioName}`;
  }
  return title;
};

export const getFeedbackChain = <T extends CallFeedbackType>(
  feedbacks: T[],
  callFeedbackId: string,
  feedbackIdMap: Record<string, T>
): T[] => {
  const feedbackChain: T[] = [];
  const feedback = feedbackIdMap[callFeedbackId];
  if (!feedback) {
    return [];
  }
  feedbackChain.push(feedback);
  const replyFeedbacks = feedbacks.filter(
    (f) => f.reply_feedback_id === feedback.call_feedback_id
  );
  if (replyFeedbacks.length > 0) {
    replyFeedbacks.sort();
    replyFeedbacks.forEach((f) => {
      const replyFeedbacks = getFeedbackChain(
        feedbacks,
        f.call_feedback_id,
        feedbackIdMap
      );
      feedbackChain.push(...replyFeedbacks);
    });
  }
  return feedbackChain;
};

/**
 * Sort the feedbacks by created_at in ascending order - if there is a feedback that refers to another feedback
 * it will be sorted after the feedback it refers to sorted by created_at in ascending order
 * @param feedbacks Feedback array
 * @returns Sorted feedback array
 */
export const sortFeedbacks = (feedbacks?: CallFeedbackType[]) => {
  if (!feedbacks) {
    return [];
  }
  const newFeedbacks = [...feedbacks];
  newFeedbacks.sort((a, b) => {
    if (a.reply_feedback_id && b.reply_feedback_id) {
      return a.created_at.localeCompare(b.created_at);
    } else if (a.reply_feedback_id) {
      return 1;
    } else if (b.reply_feedback_id) {
      return -1;
    } else {
      return a.created_at.localeCompare(b.created_at);
    }
  });
  return newFeedbacks;
};

export const getFeedbackChains = (feedbacks?: CallFeedbackType[]) => {
  if (!feedbacks) {
    return;
  }
  const parentMap = feedbacks.reduce((acc, curr) => {
    const replyFeedbackId = curr.reply_feedback_id ?? "null";
    acc[replyFeedbackId] = acc[replyFeedbackId] ?? [];
    acc[replyFeedbackId].push(curr);
    return acc;
  }, {} as Record<string, CallFeedbackType[]>);

  return (parentMap["null"] ?? []).map((f) => {
    const chain = [f];
    let nextFeedbacks = parentMap[f.call_feedback_id] ?? [];
    while (nextFeedbacks.length > 0) {
      const nextFeedback = nextFeedbacks.pop();
      if (!nextFeedback) {
        break;
      }
      chain.push(nextFeedback);
      nextFeedbacks.push(...(parentMap[nextFeedback.call_feedback_id] ?? []));
    }
    // this is probably redundant but just in case
    return sortFeedbacks(chain);
  });
};

export const getCallTypeToMethod = (callType: SnowMSGSessionTypeEnum) => {
  switch (callType) {
    case SnowMSGSessionTypeEnum.SESSION_WEB_VOICE:
      return ConfigMethodEnum.VOIP;
    case SnowMSGSessionTypeEnum.SESSION_TELEPHONE:
      return ConfigMethodEnum.PHONE;
    case SnowMSGSessionTypeEnum.SESSION_WEB_CHAT:
      return ConfigMethodEnum.CHAT;
  }
};

export const isCallStatusTerminal = (status?: CallStatusEnum): boolean => {
  return (
    status === CallStatusEnum.FINISHED ||
    status === CallStatusEnum.DROPPED ||
    status === CallStatusEnum.FAILED_TO_CONNECT ||
    status === CallStatusEnum.FAILED_WITH_ERROR
  );
};

export const isCallOutreachStatusTerminal = (
  status?: CallOutreachStatusEnum
) => {
  return (
    status === CallOutreachStatusEnum.COMPLETED ||
    status === CallOutreachStatusEnum.FAILED ||
    status === CallOutreachStatusEnum.DRAFT ||
    status === CallOutreachStatusEnum.PENDING
  );
};

export const isOutreachOverdue = (outreach: CallOutreachType) => {
  const nextAttemptAt = parseISO(outreach.next_attempt_at);
  if (nextAttemptAt && nextAttemptAt < new Date()) {
    return true;
  }
};

export const getOutreachScheduledAt = (nextAttemptAt?: Date | null) => {
  if (nextAttemptAt && nextAttemptAt < new Date()) {
    return "Now";
  }
  return formatISODateToRelative(nextAttemptAt);
};

export const getLLMModelLabel = (model?: string) => {
  if (!model) {
    return "Unknown";
  }
  const llmModelLabels: Record<string, any> = {
    "prod-G35DPO": "G35 DPO",
    "prod-G36DPO": "HP G36 DPO",
    "prod-G46": "G46",
    "prod-G46DPO": "G46 DPO",
    "prod-G54": "G54",
    "prod-G54DPO3": "G54 DPO3",
    "prod-G60": "G60",
    "prod-G61": "G61",
    "prod-G62": "G62",
    "prod-G62DPO": "G62 DPO",
    "prod-G64": "G64",
    "H2:llama-base__loracpt-3__align-10.7-signallamp2": "DEV H2",
    "prod-H2DPO": "H2 DPO",
    "prod-U1": "U1",
    "prod-H2": "Polaris 0.1",
    H2_L3: "Polaris 1.0",
  };
  return llmModelLabels[model] ?? formatEnumValue(model);
};

export const isConnectedDisposition = (disposition?: CallDispositionEnum) => {
  if (!disposition) {
    return false;
  }
  return CALL_CONNECTED_DISPOSITIONS.includes(disposition);
};

export const areAllConnectedDispositions = (
  dispositions?: CallDispositionEnum[]
) => {
  if (!dispositions) {
    return false;
  }
  return dispositions.every((d) => CALL_CONNECTED_DISPOSITIONS.includes(d));
};

export const isCompletedDisposition = (disposition?: CallDispositionEnum) => {
  if (!disposition) {
    return false;
  }
  return CALL_COMPLETED_DISPOSITIONS.includes(disposition);
};

export const areAllCompletedDispositions = (
  dispositions?: CallDispositionEnum[]
) => {
  if (!dispositions) {
    return false;
  }
  return dispositions.every((d) => CALL_COMPLETED_DISPOSITIONS.includes(d));
};

export const isIdentityVerificationDisposition = (
  disposition?: CallDispositionEnum
) => {
  if (!disposition) {
    return false;
  }
  return CALL_IDENTITY_VERIFIED_DISPOSITIONS.includes(disposition);
};

export const areAllIdentityVerificationDispositions = (
  dispositions?: CallDispositionEnum[]
) => {
  if (!dispositions) {
    return false;
  }
  return dispositions.every((d) =>
    CALL_IDENTITY_VERIFIED_DISPOSITIONS.includes(d)
  );
};

export const isNotCompletedDisposition = (
  disposition?: CallDispositionEnum
) => {
  if (!disposition) {
    return false;
  }
  return !isCompletedDisposition(disposition);
};

export const getConnectedCompletedFromDispositions = (
  dispositions?: CallDispositionEnum[]
) => {
  if (!dispositions) {
    return {
      connected: null,
      completed: null,
    };
  }
  const { connected, completed } = dispositions.reduce(
    (acc, curr) => {
      if (acc.connected !== "all") {
        if (
          (acc.connected === "connected" && !isConnectedDisposition(curr)) ||
          (acc.connected === "not_connected" && isConnectedDisposition(curr))
        ) {
          acc.connected = "all";
        } else if (isConnectedDisposition(curr)) {
          acc.connected = "connected";
        } else {
          acc.connected = "not_connected";
        }
      }
      if (acc.completed !== "all") {
        if (
          (acc.completed === "completed" && !isCompletedDisposition(curr)) ||
          (acc.completed === "not_completed" && isCompletedDisposition(curr))
        ) {
          acc.completed = "all";
        } else if (isCompletedDisposition(curr)) {
          acc.completed = "completed";
        } else {
          acc.completed = "not_completed";
        }
      }
      return acc;
    },
    { connected: null, completed: null } as {
      connected: string | null;
      completed: string | null;
    }
  );
  return {
    connected: connected === "all" ? null : connected,
    completed: completed === "all" ? null : completed,
  };
};

export const getDispositionsFromConnectedCompleted = (
  connected?: string | null,
  completed?: string | null
) => {
  let connectedDispositions = [];
  if (connected === "connected") {
    connectedDispositions = CALL_CONNECTED_DISPOSITIONS;
  } else if (connected === "not_connected") {
    connectedDispositions = CALL_NOT_CONNECTED_DISPOSITIONS;
  } else {
    connectedDispositions = ALL_CALL_DISPOSITIONS;
  }
  let completedDispositions = [];
  if (completed === "completed") {
    completedDispositions = CALL_COMPLETED_DISPOSITIONS;
  } else if (completed === "not_completed") {
    completedDispositions = CALL_NOT_COMPLETED_DISPOSITIONS;
  } else {
    completedDispositions = ALL_CALL_DISPOSITIONS;
  }
  return intersection(connectedDispositions, completedDispositions);
};

export const isCallOutputType = (obj: any): obj is CallOutputType => {
  return typeof obj === "object" && obj !== null && "output_name" in obj;
};

export const isCallOutputKickoutType = (
  callOutput: any
): callOutput is CallOutputKickoutType => {
  return callOutput.output_type === CallOutputTypeEnum.KICKOUT;
};

export const isCallOutputScheduleType = (
  callOutput: any
): callOutput is CallOutputScheduleType => {
  return callOutput.output_type === CallOutputTypeEnum.SCHEDULING;
};

export const isCallOutputFormFillType = (
  callOutput: any
): callOutput is CallOutputFormFillType => {
  return callOutput.output_type === CallOutputTypeEnum.FORM_FILL;
};

export const isCallOutputOfflineErrorsType = (
  callOutput: any
): callOutput is CallOutputOfflineErrorsType => {
  return callOutput.output_type === CallOutputTypeEnum.OFFLINE_ERRORS;
};

export const isCallOutputOfflineErrorType = (
  obj: any
): obj is CallOutputOfflineErrorType => {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "error" in obj &&
    "utterance_ids" in obj
  );
};

export const getKickoutColorFromKickoutCallOutput = (
  callOutput: CallOutputKickoutType
) => {
  const outputName = callOutput.output_name; // kickout_yellow_pain
  const outputSections = outputName.split("_");
  if (outputSections.length < 2) {
    return;
  }
  // the color is the second argument
  return outputSections[1] as CallKickoutColorEnum;
};

export const getLatestVersionCallOutputs = (outputs?: CallOutputType[]) => {
  const outputMap = new Map<string, CallOutputType>();
  outputs?.forEach((output) => {
    const existingOutput = outputMap.get(output.output_name);
    if (!existingOutput || existingOutput.version < output.version) {
      outputMap.set(output.output_name, output);
    }
  });
  return Array.from(outputMap.values());
};
