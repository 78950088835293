import { UserRoleEnum, UserTypeEnum } from "~/constants/UserConstants";
import { UserMedicalCredentialsType, UserType } from "~/types/UserTypes";
import { convertNullStrings, toLower, capitalize } from "./StringUtils";
import { cloneDeep } from "./GeneralUtils";

// higher has more permissions
export const USER_TYPE_ORDER = [
  UserTypeEnum.GENERAL,
  UserTypeEnum.EVALUATOR,
  UserTypeEnum.ADMIN,
  UserTypeEnum.SUPER_ADMIN,
];

export const userTypeHasPermissions = (
  checkedUserType: UserTypeEnum,
  targetUserType: UserTypeEnum
) => {
  return (
    USER_TYPE_ORDER.indexOf(checkedUserType) >=
    USER_TYPE_ORDER.indexOf(targetUserType)
  );
};

export const userHasPermissionOrHigher = (
  user: UserType,
  requiredUserType: UserTypeEnum
) => {
  return userTypeHasPermissions(user.user_type, requiredUserType);
};

export const getUserLabelOrCredentials = (
  name: string | null,
  userCredentials?: UserMedicalCredentialsType | null,
  overrideCredentials?: UserMedicalCredentialsType | null,
  partnerName?: string | null
) => {
  const overrideMedicalRole =
    getUserMedicalCredentialsRole(overrideCredentials);
  const overrideSpecialty =
    getUserMedicalCredentialsSpecialty(overrideCredentials);
  const overrideEmployer = getUserMedicalCredentialsEmployer(
    overrideCredentials,
    partnerName
  );
  const overrideYOE = getUserMedicalCredentialsYOE(overrideCredentials);

  const userMedicalRole = getUserMedicalCredentialsRole(userCredentials);
  const userSpecialty = getUserMedicalCredentialsSpecialty(userCredentials);
  const userEmployer = getUserMedicalCredentialsEmployer(
    userCredentials,
    partnerName
  );

  const userYOE = getUserMedicalCredentialsYOE(userCredentials);

  // if we have override credentials, we use those
  // otherwise:
  //    if we have the name then use those
  //    otherwise use the user credentials

  const hasOverrideCredentials = !!overrideMedicalRole;
  const role = hasOverrideCredentials ? overrideMedicalRole : userMedicalRole;
  const specialty = hasOverrideCredentials ? overrideSpecialty : userSpecialty;
  const employer = hasOverrideCredentials ? overrideEmployer : userEmployer;
  const yearsExperience = hasOverrideCredentials ? overrideYOE : userYOE;
  const fullLabel = getUserMedicalCredentials(
    hasOverrideCredentials ? overrideCredentials : userCredentials
  );
  const results = {
    name: hasOverrideCredentials ? name : null,
    role: role ?? "Administrator", // default to Administrator if no role
    specialty,
    yearsExperience,
    employer,
    fullLabel,
  };
  return results;
};

export const getUserMedicalCredentials = (
  medicalCredentials?: UserMedicalCredentialsType | null
) => {
  if (!medicalCredentials) {
    return null;
  }
  const {
    user_role, // eg: "Physician"
  } = medicalCredentials;
  // For nurses - we show Nurse at Employer (X yrs.)
  // For physicians - we show Physician: Role, Specialty at Employer (X yrs.)

  // if we don't have a user role, we don't have any medical credentials
  if (!user_role) {
    return null;
  }
  const roleLabel = getUserMedicalCredentialsRole(medicalCredentials);
  if (!roleLabel) {
    return null;
  }
  let label = roleLabel;
  if (user_role !== UserRoleEnum.PATIENT) {
    const specialtyLabel =
      getUserMedicalCredentialsSpecialty(medicalCredentials);
    if (specialtyLabel) {
      label += ` - ${specialtyLabel}`;
    }
    const employerLabel = getUserMedicalCredentialsEmployer(medicalCredentials);
    if (employerLabel) {
      label += ` at ${employerLabel}`;
    }
    const yearsLabel = getUserMedicalCredentialsYOE(medicalCredentials);
    if (yearsLabel) {
      label += ` (${yearsLabel})`;
    }
  }
  return label;
};

export const getUserMedicalCredentialsRole = (
  medicalCredentials?: UserMedicalCredentialsType | null
) => {
  if (!medicalCredentials) {
    return null;
  }
  const {
    user_role, // eg: "Physician"
    medical_role, // eg: "Attending"
    license_type, // eg: "RN"
  } = medicalCredentials;
  if (!user_role) {
    return null;
  }

  let label = null;
  if (user_role === UserRoleEnum.NURSE) {
    label = `Nurse`;
    if (license_type) {
      label += `, ${license_type}`;
    }
  } else if (user_role === UserRoleEnum.PHYSICIAN) {
    label = `Physician`;
    if (medical_role) {
      label += `, ${capitalize(medical_role)}`;
    }
  } else if (
    user_role === UserRoleEnum.PATIENT ||
    user_role === UserRoleEnum.ACTOR
  ) {
    label = `Patient Actor`;
  } else if (user_role === UserRoleEnum.SOCIAL_WORKER) {
    label = `Licensed Social Worker`;
  } else if (user_role === UserRoleEnum.PHYSICIAN_ASSISTANT) {
    label = `Physician's Assistant`;
  } else if (user_role === UserRoleEnum.NURSE_PRACTITIONER) {
    label = `Nurse Practitioner`;
  } else if (user_role === UserRoleEnum.INVESTOR) {
    label = `Investor`;
  }
  // Its not a nurse or a physician so we dont care
  if (!label) {
    return null;
  }
  return label;
};

export const getUserMedicalCredentialsSpecialty = (
  medicalCredentials?: UserMedicalCredentialsType | null
) => {
  if (!medicalCredentials) {
    return null;
  }
  let {
    user_role, // eg: "Physician"
    specialty, // eg: "Cardiology"
  } = medicalCredentials;
  if (!user_role) {
    return null;
  }

  specialty = convertNullStrings(specialty);
  // somehow we have "undefined"
  let label = "";
  if (specialty) {
    label += specialty;
  }
  if (!label || label === "") {
    return null;
  }
  return label;
};

export const getUserMedicalCredentialsEmployer = (
  medicalCredentials?: UserMedicalCredentialsType | null,
  partnerName?: string | null
) => {
  if (!medicalCredentials) {
    return null;
  }
  let {
    user_role, // eg: "Physician"
    current_employer, // eg: "Mayo Clinic"
  } = medicalCredentials;
  if (!user_role) {
    return null;
  }

  let label = "";
  current_employer = convertNullStrings(current_employer);
  if (
    current_employer &&
    [
      "unemployed",
      "self employed",
      "self-employed",
      "user interviews",
      "user interview",
    ].includes(toLower(current_employer))
  ) {
    current_employer = null;
  }
  if (!current_employer && partnerName) {
    current_employer = partnerName;
  }

  if (current_employer) {
    label += `${current_employer}`;
  } else {
    label += `Employer not reported`;
  }

  if (!label || label === "") {
    return null;
  }
  return label;
};

export const getUserMedicalCredentialsYOE = (
  medicalCredentials?: UserMedicalCredentialsType | null
) => {
  if (!medicalCredentials) {
    return null;
  }
  let {
    years_experience, // eg: "5"
    age,
    user_role,
  } = medicalCredentials;
  years_experience = convertNullStrings(years_experience);
  let isEstimated = false;
  if (age && !years_experience) {
    // estimate years of experience from age
    const NURSE_ESTIMATED_AGE_OF_GRADUATION = 25;
    const DOCTOR_ESTIMATED_AGE_OF_GRADUATION = 30;

    let estimatedAgeOfGraduation = NURSE_ESTIMATED_AGE_OF_GRADUATION;
    if (user_role === UserRoleEnum.PHYSICIAN) {
      estimatedAgeOfGraduation = DOCTOR_ESTIMATED_AGE_OF_GRADUATION;
    }

    const numAge = typeof age === "string" ? parseInt(age) : age;
    if (!isNaN(numAge) && numAge > estimatedAgeOfGraduation) {
      years_experience = `${numAge - estimatedAgeOfGraduation}`;
      isEstimated = true;
    }
  }
  if (years_experience) {
    return `${years_experience}${
      isEstimated ? " est." : ""
    } years of experience`;
  }
  return null;
};

export const getUserFilterSettings = (user: UserType, tableKey: string) => {
  const filters: Record<string, any> | null =
    user.user_metadata?.filter_settings?.[tableKey];
  return cloneDeep(filters);
};
