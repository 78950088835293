import { createTheme, alpha } from "@mui/material/styles";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {
  addAnnotationColors,
  addBasicColors,
  addKickoutColors,
  addSeverityColors,
} from "./BaseTheme";

export let theme = createTheme();

theme = createTheme(theme, {
  palette: {
    primary: theme.palette.augmentColor({
      color: {
        main: "#5FC958",
        contrastText: "#FFFFFF",
      },
      name: "primary",
    }),
    primaryLight: theme.palette.augmentColor({
      color: {
        main: alpha("#5FC958", 0.2),
      },
      name: "primaryLight",
    }),
    primaryDark: theme.palette.augmentColor({
      color: {
        main: alpha("#5FC958", 0.7),
      },
      name: "primaryDark",
    }),
    secondary: theme.palette.augmentColor({
      color: {
        main: "#4DA9EA",
      },
      name: "secondary",
    }),
    secondaryLight: theme.palette.augmentColor({
      color: {
        light: "#E0EDFA",
        main: "#d1e4f7",
        dark: "#c2dcf4",
      },
      name: "secondaryLight",
    }),
    tertiary: theme.palette.augmentColor({
      color: {
        main: "#F1E48A",
      },
      name: "tertiary",
    }),
    quaternary: theme.palette.augmentColor({
      color: {
        main: "#8a73c5",
      },
      name: "quaternary",
    }),
  },
});

theme = addAnnotationColors(theme);
theme = addKickoutColors(theme);
theme = addBasicColors(theme);
theme = addSeverityColors(theme);

interface SnowThemeProviderProps {
  children: React.ReactNode;
}

const SnowThemeProvider = ({ children }: SnowThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default SnowThemeProvider;
