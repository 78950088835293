import { type Theme, createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

export const addAnnotationColors = (theme: Theme) => {
  return createTheme(theme, {
    palette: {
      debug: theme.palette.augmentColor({
        color: {
          main: "#F1E48A",
        },
        name: "debug",
      }),
      task: theme.palette.augmentColor({
        color: {
          main: "#f8c3c9",
        },
        name: "task",
      }),
      system: theme.palette.augmentColor({
        color: {
          main: "#bda8f0",
        },
        name: "system",
      }),
      feedback: theme.palette.augmentColor({
        color: {
          main: "#b0dcfc",
        },
        name: "feedback",
      }),
      vote: theme.palette.augmentColor({
        color: {
          main: "#BEF4C9",
        },
        name: "vote",
      }),
      telemetry: theme.palette.augmentColor({
        color: {
          main: "#f4bf99",
        },
        name: "telemetry",
      }),
      kickout: theme.palette.augmentColor({
        color: {
          main: "#e9e3d2",
        },
        name: "kickout",
      }),
      schedule: theme.palette.augmentColor({
        color: {
          main: "#ffecd1",
        },
        name: "schedule",
      }),
      errorChecker: theme.palette.augmentColor({
        color: {
          main: "#d4ceca",
        },
        name: "errorChecker",
      }),
    },
  });
};

// These need to map to the kickout colors in kickout_spec.py
export const addKickoutColors = (theme: Theme) => {
  return createTheme(theme, {
    palette: {
      kickoutWhite: theme.palette.augmentColor({
        color: {
          main: "#FFFFFF",
        },
        name: "kickoutWhite",
      }),
      kickoutBlue: theme.palette.augmentColor({
        color: {
          main: "#4DA9EA",
        },
        name: "kickoutBlue",
      }),
      kickoutGreen: theme.palette.augmentColor({
        color: {
          main: "#5FC958",
        },
        name: "kickoutGreen",
      }),
      kickoutYellow: theme.palette.augmentColor({
        color: {
          main: "#F1E48A",
        },
        name: "kickoutYellow",
      }),
      kickoutAmber: theme.palette.augmentColor({
        color: {
          main: "#FFAA05",
        },
        name: "kickoutAmber",
      }),
      kickoutOrange: theme.palette.augmentColor({
        color: {
          main: "#FC8B5A",
        },
        name: "kickoutOrange",
      }),
      kickoutPurple: theme.palette.augmentColor({
        color: {
          main: "#8a73c5",
        },
        name: "kickoutPurple",
      }),
      kickoutRed: theme.palette.augmentColor({
        color: {
          main: "#C30000",
        },
        name: "kickoutRed",
      }),
    },
  });
};

export const addSeverityColors = (theme: Theme) => {
  return createTheme(theme, {
    palette: {
      severityHighest: theme.palette.augmentColor({
        color: {
          light: "#f1c4c4",
          main: "#C30000",
        },
        name: "severityHighest",
      }),
      severityHigh: theme.palette.augmentColor({
        color: {
          light: "#fed0bd",
          main: "#FC8B5A",
        },
        name: "severityHigh",
      }),
      severityMedium: theme.palette.augmentColor({
        color: {
          light: "#ffe1a7",
          main: "#FFAA05",
          contrastText: "#fd6a01",
        },
        name: "severityMedium",
      }),
      severityLow: theme.palette.augmentColor({
        color: {
          light: "#b2d1ef",
          main: "#1976D2",
        },
        name: "severityLow",
      }),
      severityGood: theme.palette.augmentColor({
        color: {
          light: "#cadec9",
          main: "#43883E",
        },
        name: "severityGood",
      }),
    },
  });
};

export const addBasicColors = (theme: Theme) => {
  return createTheme(theme, {
    palette: {
      neutral: theme.palette.augmentColor({
        color: {
          main: grey[600],
        },
        name: "neutral",
      }),
      neutralLight: theme.palette.augmentColor({
        color: {
          main: grey[400],
        },
        name: "neutralLight",
      }),
      basic: theme.palette.augmentColor({
        color: {
          main: grey[800],
        },
        name: "basic",
      }),
      background: {
        default: "#F7F7F7",
      },
      white: {
        main: "#FFFFFF",
        off: "#D7D7D6",
      },
      slate: {
        main: "#49454F",
      },
    },
    components: {
      MuiDataGrid: {
        // Disabling the outline on the cells for the data grid
        styleOverrides: {
          root: {
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            backgroundColor: "#fff",
          },
        },
      },
    },
  });
};
