// These are used for the default ui call config
export const RACHEL_UUID = "ed9ca802-fb12-46d8-8298-dae2d2e7f81c";
export const CHF_SCRIPT_UUID = "dfd90363-421b-4d8e-9601-a93dd42039a5";
export const JANE_ANDERSON_PATIENT_UUID =
  "108fd1f2-b8cd-45a7-ad19-c4d390615819";

export enum PartnerUUIDEnum {
  HAI_ADVISORS = "0ceff1ff-7fd6-4729-902f-532466058304",
  FRASER_HEALTH = "3dd13d76-2580-468e-b008-0f41c339c09f",
  HAI = "a3aaa68a-5af5-4b55-815a-d26c6dd8adb5",
  HMI_MEDICAL = "086a0491-5c18-45ad-9788-606c7f4a58a6",
  MMO = "bf4b2246-3713-45db-8d7c-079448d1cd9b",
}

export enum ScenarioUUIDEnum {
  EOB = "f8f07e74-aa84-40df-a079-76110750d891",
  DME = "409a7f22-a8cf-4e80-97f9-a1811398db9e",
}
